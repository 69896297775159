@font-face {
  font-family: 'Futura';
  src: url('Futura-Light.woff2') format('woff2'),
      url('Futura-Light.woff') format('woff'),
      url('Futura-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Shellana';
  src:  url('Shellana Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}